<template>
  <div class="upload">
    <kendo-upload ref="upload"
                  name="files"
                  :multiple="multiple"
                  :show-file-list="false"
                  :async-auto-upload="false"
                  :localization-select="'파일 선택'"
                  @select="onSelectFile"
                  style="width: 100%"
    >
    </kendo-upload>

    <div class="k-upload" v-show="file !== []">
      <ul class="k-upload-files k-reset" style="width: 100%">
        <li v-bind:id="imageName+index" class="k-file" v-for="(fileItem,index) in file">
          <span class="k-progress" style="width: 100%;"></span> <span class="k-file-name-size-wrapper" style="margin-left:0!important;">
            <span class="k-file-name">{{ fileItem.name }}</span>
            <span class="k-file-size" v-if="fileItem.size !== undefined">{{ Math.round(0.001 * parseInt(fileItem.size)) }} KB</span>
          </span> <strong class="k-upload-status">
          <button type="button" class="k-button k-upload-action" aria-label="Remove" @click="removeExistFiles(imageName,index)">
            <span class="k-icon k-i-close k-i-x" title="Remove"></span>
          </button>
        </strong>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'FileUploadResume',
  props: {
    file: {
      type: Array,
      default: function () {
        return []
      },
    },
    multiple: {
      default: true,
    },
    imageName: {
      default: null,
    },

  },
  data () {
    return {
      uploadFile: [],
    }
  },
  methods: {
    onSelectFile: function (e) {
      const vm = this
      const files = e.files

      // 확장자 체크
      let fileSpliceIndex = -1
      let fileIndex = this.file.length
      files.forEach(file => {
        //첨부파일이 한페이지에 여러개를 삽입 할때는 imageName을 받아서 처리한다
        if (vm.imageName !== null) {
          file.imageName = vm.imageName
          file.seq = fileIndex
        }

        const allowedExtArray = [".jpeg",".jpg",".png",".pdf",".xlsx",".xls",".docx",".doc",".xlsm",".zip",".pptx",".hwp"]
        if (!allowedExtArray.includes(file.extension.toLowerCase())) {
          // 불허 확장자 파일 index
          fileSpliceIndex = files.indexOf(file)

          kendo.alert(file.extension + "파일은 선택하실 수 없습니다.")
          e.preventDefault()
          return false
        }else if (file.size === 0) {
          fileSpliceIndex = 1
          kendo.alert('파일의 size가 0입니다 파일을 확인해 주세요.')
          e.preventDefault()
          return false
        } else {
          fileIndex++
        }
      })

      if (fileSpliceIndex < 0) {

        const fileInfo = e.files[0]
        this.uploadFile = fileInfo

        setTimeout(function () {
          const raw = fileInfo.rawFile
          const reader = new FileReader()
          const name = fileInfo.name
          const w = this

          if (raw) {

            reader.readAsDataURL(raw)
          }
        })
        this.$emit('child', files)
        this.$emit('checkInputValChanged')
      }
    },
    removeExistFiles: function (imageName, idx) {
      const id = this.file.findIndex(function (item) {
        return parseInt(item.seq) === parseInt(idx)
      })

      if (id > -1) {
        this.file.splice(id, 1)
      }
      this.$emit('remove',imageName)
      this.$emit('checkInputValChanged')
    },
  },
}
</script>
<style>
.upload span.k-file-extension {
  font-size: 10px !important;
}
</style>
